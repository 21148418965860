import React, { Fragment } from "react";

import {
  BusinessOutlined,
  LocationOnOutlined,
  MailOutline,
  PhoneInTalkTwoTone,
  ReceiptOutlined,
} from "@mui/icons-material";
import { Typography, Link } from "@mui/material";

import { CompanyInfo } from "../pages/constants";

function CompanyInformation() {
  return (
    <Fragment>
      <Typography sx={{ display: "flex", gap: 1 }}>
        <LocationOnOutlined /> {CompanyInfo.address}
      </Typography>
      <Typography sx={{ display: "flex", gap: 1 }}>
        <BusinessOutlined /> Company ID: {CompanyInfo.companyId}
      </Typography>
      <Typography sx={{ display: "flex", gap: 1 }}>
        <ReceiptOutlined /> VAT ID: {CompanyInfo.vatId}
      </Typography>
      <Typography sx={{ display: "flex", gap: 1 }}>
        <PhoneInTalkTwoTone />
        <Link href={`tel:${CompanyInfo.phone}`} sx={{ color: "inherit" }}>
          {CompanyInfo.phone}
        </Link>
      </Typography>
      <Typography sx={{ display: "flex", gap: 1 }}>
        <MailOutline />
        <Link href={`mailto:${CompanyInfo.email}`} sx={{ color: "inherit" }}>
          {CompanyInfo.email}
        </Link>
      </Typography>
    </Fragment>
  );
}

export default CompanyInformation;
