import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import {
  BoltOutlined,
  Co2Outlined,
  CompressOutlined,
  DirectionsCarOutlined,
  ElectricalServicesOutlined,
  EnergySavingsLeafOutlined,
  EngineeringOutlined,
  InventoryOutlined,
  LocalShippingOutlined,
  OpacityOutlined,
  PublicOutlined,
  RecyclingOutlined,
  SpeedOutlined,
  WorkOutlined,
} from "@mui/icons-material";

import ContentBackgroundedSection from "../components/ContentBackgroundedSection";
import ContentSection from "../components/ContentSection";
import LinkButton from "../components/ui/LinkButton";
import IconedItemsList from "../components/ui/IconedItemsList";

import GreensAndSkyLandscape from "../assets/images/GreensAndSkyLandscape.jpg";
import GreenMountainsLandscape from "../assets/images/GreenMountainsLandscape.jpg";
import InfographicBiogasToBiomethaneProcess from "../assets/images/InfographicBiogasToBiomethaneProcess.jpg";
import BiogasPlantSideView from "../assets/images/BiogasPlantSideView.jpg";
import BiogasPlantAerialView from "../assets/images/BiogasPlantAerialView.jpg";
import InfographicPurificationLiquefactionCompression from "../assets/images/InfographicPurificationLiquefactionCompression.jpg";
import BiogasCar from "../assets/images/BiogasCar.jpg";
import BiogasFacilities from "../assets/images/BiogasFacilities.jpg";
import NighttimeTruckRoad from "../assets/images/NighttimeTruckRoad.jpg";
import HeroSectionV2 from "../components/HeroSectionV2";
import { useLanguage } from "../utils/languages/LanguageContext";
import { assignIcons } from "../utils/utils";

function BiogasPage() {
  const { getTranslationKeyData } = useLanguage();
  const text = getTranslationKeyData("pages.biogas");

  return (
    <Fragment>
      <HeroSectionV2
        heading={text.hero.mainHeaderTitle}
        subheading={text.hero.mainHeaderSubtitle}
      >
        <Box display="flex" gap={6} flexDirection={{ xs: "column", lg: "row" }}>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography
              color="#001b35"
              variant="h6"
              sx={{ flex: 1, flexShrink: 0 }}
            >
              {text.hero.heroSectionTitle}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.hero.heroSectionContent.p1}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.hero.heroSectionContent.p2}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              backgroundImage: `url(${GreensAndSkyLandscape})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 8,
              minHeight: "200px",
            }}
          />
        </Box>
      </HeroSectionV2>
      <ContentSection>
        <Box
          marginTop={{ xs: 0, lg: 6 }}
          sx={{
            borderTop: "5px solid #1B5E20",
            borderRadius: 6,
            paddingTop: 6,
            textAlign: "center",
          }}
        >
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography
              color="#001b35"
              variant="h3"
              sx={{
                flex: 1,
                flexShrink: 0,
                maxWidth: "600px",
                margin: "0 auto",
              }}
            >
              {text.plantsSection.title}
            </Typography>
            <Typography
              color="#001b35"
              variant="body1"
              margin="0 auto"
              marginTop={3}
              maxWidth={1000}
            >
              {text.plantsSection.description}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <img
              alt="Infographic of the biogas-to-biomethane process"
              src={InfographicBiogasToBiomethaneProcess}
              style={{
                width: "600px",
                maxWidth: "100%",
                margin: "0 auto",
                marginTop: "48px",
              }}
            />
          </Box>
        </Box>
      </ContentSection>
      <ContentSection>
        <Box
          marginTop={{ xs: 0, lg: 6 }}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            gap: 6,
          }}
        >
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <img
              alt=""
              src={BiogasPlantSideView}
              style={{
                borderTop: "5px solid #1B5E20",
                borderRadius: "32px 32px 3px 3px",
                width: "100%",
                height: "300px",
                objectFit: "cover",
              }}
            />
            <Typography marginTop={2} color="#001b35" variant="h6">
              {text.investmentAims.heading}
            </Typography>
            <IconedItemsList
              marginBlockStart={2}
              items={assignIcons(text.investmentAims.items, [
                RecyclingOutlined,
                EngineeringOutlined,
              ])}
            />
          </Box>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <img
              alt=""
              src={BiogasPlantAerialView}
              style={{
                borderTop: "5px solid #1B5E20",
                borderRadius: "32px 32px 3px 3px",
                width: "100%",
                height: "300px",
                objectFit: "cover",
              }}
            />
            <Typography marginTop={2} color="#001b35" variant="h6">
              {text.developmentRole.heading}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.developmentRole.description}
            </Typography>
            <IconedItemsList
              marginBlockStart={2}
              items={assignIcons(text.developmentRole.items, [
                EnergySavingsLeafOutlined,
                ElectricalServicesOutlined,
              ])}
            />
          </Box>
        </Box>
      </ContentSection>
      <ContentSection>
        <Box
          marginTop={{ xs: 0, lg: 6 }}
          sx={{
            borderTop: "5px solid #1B5E20",
            borderRadius: 6,
            paddingTop: 6,
            textAlign: "center",
          }}
        >
          <Typography
            color="#001b35"
            variant="h3"
            sx={{ flex: 1, flexShrink: 0 }}
          >
            {text.bioLngbioCngSection.title}
          </Typography>
          <Typography
            color="#001b35"
            variant="body1"
            margin="0 auto"
            marginTop={3}
            maxWidth={1000}
          >
            {text.bioLngbioCngSection.description}
          </Typography>
          <img
            alt="Infographic of purification, liquefaction, compression"
            src={InfographicPurificationLiquefactionCompression}
            style={{
              width: "700px",
              maxWidth: "100%",
              margin: "0 auto",
              marginTop: "48px",
            }}
          />
        </Box>
      </ContentSection>
      <ContentSection>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            gap: 6,
          }}
        >
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <img
              alt=""
              src={BiogasCar}
              style={{
                borderTop: "5px solid #1B5E20",
                borderRadius: "32px 32px 3px 3px",
                width: "100%",
                height: "300px",
                objectFit: "cover",
              }}
            />
            <Typography marginTop={2} color="#001b35" variant="h6">
              {text.bioCNG.heading}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.bioCNG.description}
            </Typography>
            <IconedItemsList
              marginBlockStart={2}
              items={assignIcons(text.bioCNG.items, [
                DirectionsCarOutlined,
                WorkOutlined,
              ])}
            />
          </Box>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <img
              alt=""
              src={BiogasFacilities}
              style={{
                borderTop: "5px solid #1B5E20",
                borderRadius: "32px 32px 3px 3px",
                width: "100%",
                height: "300px",
                objectFit: "cover",
              }}
            />
            <Typography marginTop={2} color="#001b35" variant="h6">
              {text.bioLNG.heading}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.bioLNG.description}
            </Typography>
            <IconedItemsList
              marginBlockStart={2}
              items={assignIcons(text.bioLNG.items, [
                LocalShippingOutlined,
                PublicOutlined,
              ])}
            />
          </Box>
        </Box>
      </ContentSection>
      <ContentSection
        sx={{
          padding: { xs: 0 },
          marginTop: { xs: 6, lg: 8 },
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(to bottom, #E8F5E9, white)",
            borderRadius: 6,
            padding: { xs: 4, lg: 6 },
          }}
        >
          <Box
            display="flex"
            gap={6}
            flexDirection={{ xs: "column", lg: "row" }}
          >
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography color="#001b35" variant="h6" marginTop={2}>
                {text.volumeConversionSection.heading}
              </Typography>
              <Typography color="#001b35" variant="body1" marginTop={2}>
                {text.volumeConversionSection.description}
              </Typography>
              <IconedItemsList
                marginBlockStart={2}
                items={assignIcons(text.volumeConversionSection.items, [
                  SpeedOutlined,
                  CompressOutlined,
                  OpacityOutlined,
                ])}
              />
            </Box>
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <img
                alt=""
                src={NighttimeTruckRoad}
                style={{
                  borderRadius: 32,
                  width: "100%",
                  maxHeight: "500px",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            gap={6}
            marginBlockStart={6}
            flexDirection={{ xs: "column", lg: "row" }}
          >
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography color="#001b35" variant="h6" marginTop={2}>
                {text.environmentalBenefits.heading}
              </Typography>
              <Typography color="#001b35" variant="body1" marginTop={2}>
                {text.environmentalBenefits.description}
              </Typography>
              <IconedItemsList
                marginBlockStart={2}
                items={assignIcons(text.environmentalBenefits.items, [
                  Co2Outlined,
                  LocalShippingOutlined,
                ])}
              />
            </Box>
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography color="#001b35" variant="h6" marginTop={2}>
                {text.energyEfficiency.heading}
              </Typography>
              <Typography color="#001b35" variant="body1" marginTop={2}>
                {text.energyEfficiency.description}
              </Typography>
              <IconedItemsList
                marginBlockStart={2}
                items={assignIcons(text.energyEfficiency.items, [
                  BoltOutlined,
                  InventoryOutlined,
                ])}
              />
            </Box>
          </Box>
        </Box>
      </ContentSection>
      <ContentBackgroundedSection
        backgroundImage={GreenMountainsLandscape}
        sx={{ marginTop: 8, marginBottom: 8 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            margin: "0 auto",
            gap: 6,
            maxWidth: "1170px",
          }}
        >
          <Box sx={{ flex: 1, flexShrink: 0, paddingRight: { xs: 0, lg: 3 } }}>
            <Typography color="white" variant="h2" marginBlockStart={2}>
              {text.visionSection.heading}
            </Typography>
            <Typography
              color="white"
              variant="body1"
              fontWeight={500}
              marginBlockStart={1}
            >
              {text.visionSection.subheading}
            </Typography>
          </Box>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography
              color="white"
              variant="body1"
              fontWeight={500}
              fontStyle={"italic"}
            >
              {text.visionSection.description}
            </Typography>
            <Box marginTop={2}>
              <LinkButton color="tertiary" to="/contact">
              {text.visionSection.cta}
              </LinkButton>
            </Box>
          </Box>
        </Box>
      </ContentBackgroundedSection>
    </Fragment>
  );
}

export default BiogasPage;
