import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import {
  DeveloperBoardOutlined,
  EnergySavingsLeafOutlined,
  GroupsOutlined,
  LightbulbOutlined,
  PrecisionManufacturingOutlined,
  SupportAgentOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";

import LinkButton from "../components/ui/LinkButton";
import ContentSection from "../components/ContentSection";
import CompactCard from "../components/CompactCard";
import IconedItemsList from "../components/ui/IconedItemsList";

import BlueMountains from "../assets/images/BlueMountains.webp";
import SunnyMountains from "../assets/images/SunnyMountains.jpg";
import BackgroundImageBox from "../components/ui/BackgroundImageBox";
import HeroSectionV2 from "../components/HeroSectionV2";
import { useLanguage } from "../utils/languages/LanguageContext";
import { assignIcons } from "../utils/utils";

function AboutPage() {
  const { getTranslationKeyData } = useLanguage();
  const text = getTranslationKeyData("pages.about");

  return (
    <Fragment>
      <HeroSectionV2
        heading={text.hero.mainHeaderTitle}
        subheading={text.hero.mainHeaderSubtitle}
      >
        <Box display="flex" gap={6} flexDirection={{ xs: "column", lg: "row" }}>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography color="#001b35" variant="h6">
              {text.hero.heroSectionTitle}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.hero.heroSectionContent}
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: 4,
                gap: 1,
              }}
            >
              <LinkButton to="/biogas" size="large">
                {text.hero.heroSectionCTA}
              </LinkButton>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              backgroundImage: `url(${BlueMountains})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 8,
              minHeight: "200px",
            }}
          />
        </Box>
      </HeroSectionV2>
      <Box
        sx={{
          // backgroundImage: `url(${Svg})`,
          backgroundSize: { xs: "100%", lg: "60%" },
          backgroundPosition: {
            xs: "200px 40%",
            lg: "bottom -400px left -200px",
          },
          backgroundRepeat: "no-repeat",
        }}
      >
        <ContentSection>
          <Box
            sx={{
              flex: 1,
              flexShrink: 0,
              textAlign: "center",
              margin: "0 auto",
            }}
          >
            <Typography color="#001b35" variant="h3" marginTop={2}>
              {text.missionSection.heading}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.missionSection.description}
            </Typography>
            <Box
              sx={{
                flexDirection: { xs: "column", lg: "row" },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 3,
                gap: 1,
              }}
            >
              <LinkButton to="/biogas" size="large">
                Explore Biogas Solutions
              </LinkButton>
              <LinkButton to="/energy" size="large">
                Explore Energy tech
              </LinkButton>
            </Box>
          </Box>
        </ContentSection>
      </Box>
      <BackgroundImageBox image={BlueMountains}>
        <Box sx={{ flex: 1, flexShrink: 0 }}>
          <Typography color="#001b35" variant="h2" marginBlockStart={2}>
            {text.whatWeDoSection.heading}
          </Typography>
          <Typography color="#001b35" variant="body1" marginTop={2}>
            {text.whatWeDoSection.description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", lg: "row" },
            flexShrink: 0,
            gap: 4,
            marginTop: 4,
          }}
        >
          {assignIcons(text.whatWeDoSection.items, [
            DeveloperBoardOutlined,
            EnergySavingsLeafOutlined,
            PrecisionManufacturingOutlined,
            SupportAgentOutlined,
          ]).map(({ heading, description, icon }) => (
            <Box
              key={heading}
              boxSizing="border-box"
              width={{ sx: "100%", lg: "25%" }}
            >
              <CompactCard
                key={heading}
                icon={icon}
                title={heading}
                description={description}
                sx={{ textAlign: "left" }}
              />
            </Box>
          ))}
        </Box>
      </BackgroundImageBox>
      <Box
        backgroundColor="#E8F5E9"
        sx={{ marginBottom: { xs: "200px", lg: "150px" } }}
      >
        <ContentSection>
          <Box
            sx={{
              padding: { xs: 0, lg: 6 },
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "center",
              gap: 6,
            }}
          >
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography color="#001b35" variant="h6">
                {text.valuesSection.heading}
              </Typography>
              <IconedItemsList
                marginBlockStart={4}
                textAlign="left"
                items={assignIcons(text.valuesSection.items, [
                  LightbulbOutlined,
                  EnergySavingsLeafOutlined,
                  VerifiedOutlined,
                  GroupsOutlined,
                ])}
              />
            </Box>
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <img
                alt=""
                src={SunnyMountains}
                style={{
                  borderRadius: 32,
                  marginBottom: "-200px",
                  height: "550px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
        </ContentSection>
      </Box>
    </Fragment>
  );
}

export default AboutPage;
