import React from 'react';
import { Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

function LinkButton({ to, variant = 'contained', color = 'primary', size = 'large', children, ...props }) {
  return (
    <Button
      component={RouterLink}
      to={to}
      variant={variant}
      color={color}
      size={size}
      disableElevation
      sx={{
        fontWeight: 500,
        ...props.sx, // Allow for additional styling through the sx prop
      }}
      {...props} // Allow passing other props like sx, startIcon, endIcon, etc.
    >
      {children}
    </Button>
  );
}

export default LinkButton;
