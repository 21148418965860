import React from "react";
import { Box, Typography } from "@mui/material";

import PropTypes from "prop-types";

function IconedItem({ icon = undefined, title, description }) {
  const IconComponent = icon;

  return (
    <Box display="flex">
      {IconComponent && (
        <IconComponent
          sx={{ fontSize: 32, color: "primary.dark", marginRight: 2 }}
        />
      )}
      <Typography variant="body1" tag="span" color="text.primary">
        {title}
        {description && (
          <Typography variant="body1" tag="span" color="text.secondary">
            {description}
          </Typography>
        )}
      </Typography>
    </Box>
  );
}

IconedItem.propTypes = {
  action: PropTypes.node,
  icon: PropTypes.elementType,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default IconedItem;
