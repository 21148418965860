export const LanguageLabels = {
  en: "English",
  cs: "Czech",
  de: "German",
};

export const LanguageCodes = {
  en: "en",
  cs: "cs",
  de: 'de',
};

export function getDefaultUserLanguage() {
  const userLocale = navigator.language || navigator.userLanguage;
  const isCzechLocale = userLocale.startsWith(LanguageCodes.cs);
  return isCzechLocale ? LanguageCodes.cs : LanguageCodes.en;
};
