import React, { createContext, useState, useContext } from "react";

import translationsEN from "./translations/en.json";
import translationsCS from "./translations/cs.json";
import translationsDE from "./translations/de.json";

import PropTypes from "prop-types";
import { getDefaultUserLanguage, LanguageCodes } from "./languageContants";

const LanguageContext = createContext();

const translations = {
  [LanguageCodes.en]: translationsEN,
  [LanguageCodes.cs]: translationsCS,
  [LanguageCodes.de]: translationsDE,
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(getDefaultUserLanguage());

  const switchLanguage = (lang) => {
    setLanguage(lang);
  };

  // get translation key data, e.g. header.hero.title would return "Welcome to Flamestone"
  const getTranslationKeyData = (key) => {
    return (
      key
        .split(".")
        .reduce((acc, part) => acc && acc[part], translations[language]) || key
    );
  };

  return (
    <LanguageContext.Provider
      value={{ language, switchLanguage, getTranslationKeyData }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node,
};

export const useLanguage = () => useContext(LanguageContext);
