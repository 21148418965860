import React from "react";
import { NavLink } from "react-router-dom";

import { useLanguage } from "../utils/languages/LanguageContext";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import PropTypes from "prop-types";

import Logo from "../assets/images/logo.png";
import LanguageSwitch from "./LanguageSwitch";

const NavItem = ({ url, name, onClick }) => (
  <Button
    color="inherit"
    component={NavLink}
    to={url}
    onClick={onClick}
    sx={{
      textTransform: "none",
      fontSize: 16,
      letterSpacing: 0.5,
      fontWeight: 350,
    }}
  >
    {name}
  </Button>
);

NavItem.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Header() {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { getTranslationKeyData } = useLanguage();
  const text = getTranslationKeyData("header");

  const Links = [
    { name: text.links.biogas, url: "/biogas" },
    { name: text.links.energyTech, url: "/energy" },
    { name: text.links.about, url: "/about" },
    { name: text.links.contact, url: "/contact" },
  ];

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {Links.map((link) => (
        <MenuItem
          key={link.url}
          component={NavLink}
          to={link.url}
          onClick={handleMobileMenuClose}
        >
          {link.name}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <AppBar position="fixed" sx={{ backgroundColor: "#475C45" }}>
      <Container maxWidth="xl">
        <Toolbar>
          {/* Logo (CompanyName) on the left for all screen sizes */}
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex" },
              justifyContent: "flex-start",
            }}
          >
            <Button
              color="inherit"
              component={NavLink}
              to="/"
              sx={{ textTransform: "none" }}
            >
              <img src={Logo} alt="logo" loading="lazy" height={40} />{" "}
              <Typography
                variant="body1"
                sx={{ fontWeight: 500, marginLeft: 1 }}
              >
                Flamestone
              </Typography>
            </Button>
          </Typography>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>
            {Links.map((link) => (
              <NavItem key={link.url} url={link.url} name={link.name} />
            ))}
          </Box>

          <LanguageSwitch />
          
          {/* Mobile Burger Menu on the right */}
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              aria-controls="mobile-menu"
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </Container>
      {renderMobileMenu}
    </AppBar>
  );
}

export default Header;
