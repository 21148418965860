import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import {
  AutoAwesomeOutlined,
  BuildOutlined,
  DeveloperBoardOutlined,
  DnsOutlined,
  EnergySavingsLeafOutlined,
  HomeWorkOutlined,
  InsightsOutlined,
  IntegrationInstructionsOutlined,
  LanguageOutlined,
  LocalShippingOutlined,
  MemoryOutlined,
  PowerOutlined,
  PrecisionManufacturingOutlined,
  PublicOutlined,
  SensorsOutlined,
  StarsOutlined,
  StorageOutlined,
  UnfoldMoreOutlined,
  VerifiedOutlined,
} from "@mui/icons-material";

import ContentSection from "../components/ContentSection";
import CompactCard from "../components/CompactCard";
import IconedItemsList from "../components/ui/IconedItemsList";
import LinkButton from "../components/ui/LinkButton";

import ConnectionLinesInTheSunset from "../assets/images/ConnectionLinesInTheSunset.jpg";
import Wires from "../assets/images/Wires.jpg";
import LinesOfCode from "../assets/images/LinesOfCode.jpg";
import FactorySetting from "../assets/images/FactorySetting.jpg";
import ItInfrastuctureCityScape from "../assets/images/ItInfrastuctureCityScape.jpg";
import SolarPanels from "../assets/images/SolarPanels.jpg";
import BackgroundImageBox from "../components/ui/BackgroundImageBox";
import HeroSectionV2 from "../components/HeroSectionV2";
import { useLanguage } from "../utils/languages/LanguageContext";
import { assignIcons } from "../utils/utils";

function EnergyPage() {
  const { getTranslationKeyData } = useLanguage();
  const text = getTranslationKeyData("pages.energy");

  return (
    <Fragment>
      <HeroSectionV2
        heading={text.hero.mainHeaderTitle}
        subheading={text.hero.mainHeaderSubtitle}
        dark
      >
        <Box display="flex" gap={6} flexDirection={{ xs: "column", lg: "row" }}>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography
              color="#001b35"
              variant="h4"
              sx={{ flex: 1, flexShrink: 0 }}
            >
              {text.hero.heroSectionTitle}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.hero.heroSectionContent.p1}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.hero.heroSectionContent.p2}
            </Typography>
          </Box>
          <Box
            sx={{
              display: { xs: "none", lg: "block" },
              flex: 1,
              backgroundImage: `url(${ConnectionLinesInTheSunset})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 8,
              minHeight: "200px",
            }}
          />
        </Box>
      </HeroSectionV2>
      <BackgroundImageBox image={Wires}>
        <Box sx={{ flex: 1, flexShrink: 0 }}>
          <Typography color="#001b35" variant="h2" marginBlockStart={2}>
            {text.dataCentersSection.heading}
          </Typography>
          <Typography color="#001b35" variant="body1" marginTop={2}>
            {text.dataCentersSection.description}
          </Typography>
        </Box>
        <Typography color="#001b35" variant="h6" marginBlockStart={6}>
          {text.dataCentersSection.offerings.heading}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", lg: "row" },
            flexShrink: 0,
            gap: 4,
            marginTop: 4,
          }}
        >
          {assignIcons(text.dataCentersSection.offerings.items, [
            DnsOutlined,
            StorageOutlined,
            MemoryOutlined,
            DeveloperBoardOutlined,
          ]).map(({ heading, description, icon }) => (
            <Box
              key={heading}
              boxSizing="border-box"
              width={{ sx: "100%", lg: "25%" }}
            >
              <CompactCard
                key={heading}
                icon={icon}
                title={heading}
                description={description}
                sx={{ textAlign: "left" }}
              />
            </Box>
          ))}
        </Box>
      </BackgroundImageBox>
      <Box
        backgroundColor="#E8F5E9"
        sx={{ marginBottom: { xs: "200px", lg: "150px" } }}
      >
        <ContentSection>
          <Box
            sx={{
              padding: { xs: 0, lg: 6 },
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "center",
              gap: 6,
            }}
          >
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography color="#001b35" variant="h6">
                {text.keyBenefitsSection.heading}
              </Typography>
              <IconedItemsList
                marginBlockStart={4}
                textAlign="left"
                items={assignIcons(text.keyBenefitsSection.items, [
                  BuildOutlined,
                  UnfoldMoreOutlined,
                  EnergySavingsLeafOutlined,
                ])}
              />
            </Box>
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <img
                alt=""
                src={ItInfrastuctureCityScape}
                style={{
                  borderRadius: 32,
                  marginBottom: "-200px",
                  height: "450px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
        </ContentSection>
      </Box>
      <BackgroundImageBox image={LinesOfCode}>
        <Box sx={{ flex: 1, flexShrink: 0 }}>
          <Typography color="#001b35" variant="h2" marginBlockStart={2}>
            {text.customSoftwareSection.heading}
          </Typography>
          <Typography color="#001b35" variant="body1" marginTop={2}>
            {text.customSoftwareSection.description}
          </Typography>
        </Box>
        <Typography color="#001b35" variant="h6" marginBlockStart={6}>
          {text.customSoftwareSection.capabilities.heading}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", lg: "row" },
            flexShrink: 0,
            gap: 4,
            marginTop: 4,
          }}
        >
          {assignIcons(text.customSoftwareSection.capabilities.items, [
            InsightsOutlined,
            SensorsOutlined,
            LanguageOutlined,
            AutoAwesomeOutlined,
          ]).map(({ heading, description, icon }) => (
            <Box
              key={heading}
              boxSizing="border-box"
              width={{ sx: "100%", lg: "25%" }}
            >
              <CompactCard
                key={heading}
                icon={icon}
                title={heading}
                description={description}
                sx={{ textAlign: "left" }}
              />
            </Box>
          ))}
        </Box>
      </BackgroundImageBox>
      <BackgroundImageBox image={FactorySetting}>
        <Box sx={{ flex: 1, flexShrink: 0 }}>
          <Typography color="#001b35" variant="h2" marginBlockStart={2}>
            {text.rndSection.heading}
          </Typography>
          <Typography color="#001b35" variant="body1" marginTop={2}>
            {text.rndSection.description}
          </Typography>
        </Box>
        <Typography color="#001b35" variant="h6" marginBlockStart={6}>
          {text.rndSection.highlights.heading}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", lg: "row" },
            flexShrink: 0,
            gap: 4,
            marginTop: 4,
          }}
        >
          {assignIcons(text.rndSection.highlights.items, [
            PublicOutlined,
            VerifiedOutlined,
            PrecisionManufacturingOutlined,
          ]).map(({ heading, description, icon }) => (
            <Box
              key={heading}
              boxSizing="border-box"
              width={{ sx: "100%", lg: "33%" }}
            >
              <CompactCard
                key={heading}
                icon={icon}
                title={heading}
                description={description}
                sx={{ textAlign: "left" }}
              />
            </Box>
          ))}
        </Box>
      </BackgroundImageBox>
      <Box
        backgroundColor="#E8F5E9"
        sx={{ marginBottom: { xs: "150px", lg: "100px" } }}
      >
        <ContentSection>
          <Box
            sx={{
              padding: { xs: 0, lg: 6 },
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              justifyContent: "center",
              gap: 6,
            }}
          >
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <Typography color="#001b35" variant="h6">
                {text.applicationsSection.heading}
              </Typography>
              <Typography color="#001b35" variant="body1" marginBlockStart={2}>
                {text.applicationsSection.description}
              </Typography>
              <IconedItemsList
                marginBlockStart={4}
                textAlign="left"
                items={assignIcons(text.applicationsSection.items, [
                  PowerOutlined,
                  LocalShippingOutlined,
                  HomeWorkOutlined,
                ])}
              />
            </Box>
            <Box sx={{ flex: 1, flexShrink: 0 }}>
              <img
                alt=""
                src={SolarPanels}
                style={{
                  borderRadius: 32,
                  marginBottom: "-200px",
                  height: "550px",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Box>
        </ContentSection>
      </Box>
      <ContentSection>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            margin: "48px auto",
            gap: 6,
          }}
        >
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography color="#001b35" variant="h3">
              {text.promoSection.heading}
            </Typography>
            <Typography color="#001b35" variant="subtitle2" marginTop={2}>
              {text.promoSection.description}
            </Typography>
            <Box
              sx={{
                marginTop: 4,
                gap: 1,
              }}
            >
              <LinkButton to="/biogas" size="large">
                {text.promoSection.cta}
              </LinkButton>
            </Box>
          </Box>

          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography color="#001b35">
              <Typography variant="body1">
                {text.promoSection.provisions.heading}
              </Typography>
            </Typography>
            <IconedItemsList
              marginBlockStart={4}
              textAlign="left"
              maxWidth={500}
              items={assignIcons(text.promoSection.provisions.items, [
                IntegrationInstructionsOutlined,
                EnergySavingsLeafOutlined,
                StarsOutlined,
              ])}
            />
          </Box>
        </Box>
      </ContentSection>
    </Fragment>
  );
}

export default EnergyPage;
