import React from "react";
import { Box } from "@mui/material";

import Logo from "../assets/images/logo.png";
import CompanyInformation from "./CompanyInformation";

export default function Footer() {
  return (
    <Box color="white">
      <Box
        sx={{
          backgroundColor: "#475C45",
          display: "flex",
          flexDirection: { xs: "column", lg: "row" },
          gap: 5,
          padding: { xs: 4, lg: 6 },
        }}
      >
        <Box>
          <img alt="logo" src={Logo} width={75} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
            flexGrow: 0,
          }}
        >
          <CompanyInformation />
        </Box>
      </Box>
    </Box>
  );
}
