import React, { useState } from "react";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { LanguageCodes } from "../utils/languages/languageContants";
import { useLanguage } from "../utils/languages/LanguageContext";

export default function LanguageSwitch() {
  const [anchorEl, setAnchorEl] = useState(null);
  const { language: activeLanguage, switchLanguage } = useLanguage();
  const otherLanguageOptions = Object.values(LanguageCodes).filter(
    (code) => code !== activeLanguage
  );

  const open = Boolean(anchorEl);
  const onLanguageButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const buttonId = "language-switch-button";
  const menuId = "languages-menu";

  return (
    <div>
      <Button
        id={buttonId}
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={onLanguageButtonClick}
        sx={{
          color: "white",
        }}
      >
        {activeLanguage.toUpperCase()}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": buttonId,
        }}
      >
        {otherLanguageOptions.map((langCode) => (
          <MenuItem
            key={langCode}
            onClick={() => {
              switchLanguage(langCode);
              handleClose();
            }}
          >
            {langCode.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
