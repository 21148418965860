import React, { Fragment } from "react";
import { Box, Typography } from "@mui/material";
import {
  LanguageOutlined,
  SensorsOutlined,
  SettingsSuggestOutlined,
  StorageOutlined,
} from "@mui/icons-material";

import ContentSection from "../components/ContentSection";
import LinkButton from "../components/ui/LinkButton";
import CompactCard from "../components/CompactCard";

import DataCenterServers from "../assets/images/DataCenterServers.jpg";
import MountainsTreeLake from "../assets/images/MountainTreesLake.jpg";
import InfographicBiogasToBiomethaneProcess from "../assets/images/InfographicBiogasToBiomethaneProcess.jpg";
import BackgroundImageBox from "../components/ui/BackgroundImageBox";
import HeroSectionV2 from "../components/HeroSectionV2";

import { useLanguage } from "../utils/languages/LanguageContext";

function HomePage() {
  const { getTranslationKeyData } = useLanguage();
  const text = getTranslationKeyData("pages.home");

  return (
    <Fragment>
      <HeroSectionV2
        heading={text.hero.mainHeaderTitle}
        subheading={text.hero.mainHeaderSubtitle}
      >
        <Box display="flex" gap={6} flexDirection={{ xs: "column", lg: "row" }}>
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography
              color="#001b35"
              variant="h3"
              sx={{ flex: 1, flexShrink: 0 }}
            >
              {text.hero.heroSectionTitle}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.hero.heroSectionContent}
            </Typography>
            <Box
              sx={{
                display: "flex",
                marginTop: 4,
                gap: 1,
              }}
            >
              <LinkButton to="/biogas" size="large">
                {text.hero.heroSectionCTA}
              </LinkButton>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,
              backgroundImage: `url(${MountainsTreeLake})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              borderRadius: 8,
              minHeight: "200px",
            }}
          />
        </Box>
      </HeroSectionV2>
      <ContentSection sx={{ marginTop: { lg: 6 } }}>
        <Box
          sx={{
            margin: "0 auto",
            textAlign: "center",
            maxWidth: "900px",
          }}
        >
          <Typography
            color="#001b35"
            variant="h2"
            sx={{ flex: 1, flexShrink: 0 }}
          >
            {text.biogasSection.title}
          </Typography>
          <Typography color="#001b35" variant="body1" marginTop={3}>
            {text.biogasSection.content}
          </Typography>
          <img
            alt="Infographic of the biogas-to-biomethane process"
            src={InfographicBiogasToBiomethaneProcess}
            style={{
              maxWidth: "100%",
              maxHeight: "450px",
              marginTop: "48px",
            }}
          />
          <Box
            sx={{
              marginTop: 4,
              gap: 1,
            }}
          >
            <LinkButton to="/biogas" size="large">
              {text.biogasSection.cta}
            </LinkButton>
          </Box>
        </Box>
      </ContentSection>
      <BackgroundImageBox image={DataCenterServers}>
        <Box sx={{ flex: 1, flexShrink: 0 }}>
          <Typography color="#001b35" variant="h2" marginBlockStart={2}>
            {text.greenTechSection.title}
          </Typography>
          <Typography color="#001b35" variant="body1" marginTop={2}>
            {text.greenTechSection.content}
          </Typography>
        </Box>
        <Typography color="#001b35" variant="h6" marginBlockStart={6}>
          {text.greenTechSection.offeringTitle}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", lg: "row" },
            flexShrink: 0,
            gap: 4,
            marginTop: 4,
          }}
        >
          {[
            {
              icon: SensorsOutlined,
            },
            {
              icon: StorageOutlined,
            },
            {
              icon: SettingsSuggestOutlined,
            },
            {
              icon: LanguageOutlined,
            },
          ].map(({ icon }, index) => {
            const itemText = text.greenTechSection.offeringItems[index];
            return (
              <Box
                key={itemText}
                boxSizing="border-box"
                width={{ sx: "100%", lg: "25%" }}
              >
                <CompactCard
                  icon={icon}
                  title={itemText}
                  sx={{ textAlign: "left" }}
                />
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            marginTop: 6,
            gap: 1,
          }}
        >
          <LinkButton to="/energy" size="large">
            {text.greenTechSection.cta}
          </LinkButton>
        </Box>
      </BackgroundImageBox>
      <ContentSection>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "center",
            margin: "32px auto",
            gap: 6,
          }}
        >
          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography color="#001b35" variant="h3">
              {text.promoSection.heading}
            </Typography>
            <Typography color="#001b35" variant="body1" marginTop={2}>
              {text.promoSection.description}
            </Typography>
          </Box>

          <Box sx={{ flex: 1, flexShrink: 0 }}>
            <Typography color="#001b35">
              <Typography variant="subtitle1">
                {text.promoSection.benefitsHeading}
              </Typography>
            </Typography>
            <Box
              sx={{
                display: "flex",
                direction: "row",
                flexWrap: "wrap",
                marginTop: 3,
                gap: 1,
              }}
            >
              {text.promoSection.benefitsItems.map((need) => (
                <Typography key={need}>✔ {need}</Typography>
              ))}
            </Box>
          </Box>
        </Box>
      </ContentSection>
    </Fragment>
  );
}

export default HomePage;
