import { createTheme } from "@mui/material/styles";

const muiTheme = createTheme({
  palette: {
    primary: {
      light: "#4CAF50",
      main: "#2E7D32",
      dark: "#1B5E20",
      contrastText: "#FFFFFF", // White for contrast
    },
    secondary: {
      light: "#f8c0d4", 
      main: "#ae3561",
      dark: "#872e57", 
      contrastText: "#FFFFFF", // White for contrast
    },
    tertiary: {
      light: "#FFFFFF", 
      main: "#F8F8F8",
      dark: "#E8E8E8", 
      contrastText: "#000000", // White for contrast
    },
    // error: {
    //   light: "#E57373", 
    //   main: "#C0392B", 
    //   dark: "#A93226", 
    //   contrastText: "#FFFFFF", 
    // },
    // warning: {
    //   light: "#FFB74D",
    //   main: "#F39C12",
    //   dark: "#E67E22",
    //   contrastText: "#000000",
    // },
    // info: {
    //   light: "#5DADE2", 
    //   main: "#3498DB", 
    //   dark: "#2C81BA",
    //   contrastText: "#FFFFFF",
    // },
    // success: {
    //   light: "#58D68D", 
    //   main: "#27AE60", 
    //   dark: "#1F7A45",
    //   contrastText: "#FFFFFF", 
    // },
    // text: {
    //   primary: "#2C3E50",
    //   secondary: "#34495E", 
    //   disabled: "#95A5A6", 
    // },
    
  },
  typography: {
    fontFamily: '"Noto Sans", serif', // Default font for all text
    h1: {
      fontFamily: "'Ubuntu', serif",
      fontWeight: 700,
      fontSize: '48px',
      lineHeight: '58px',
      '@media (max-width:600px)': {
        fontSize: '40px',
        lineHeight: '44px',
      },
    },
    h2: {
      fontFamily: "'Ubuntu', serif",
      fontWeight: 700,
      fontSize: '44px',
      lineHeight: '52px',
      '@media (max-width:600px)': {
        fontSize: '36px',
        lineHeight: '42px',
      },
    },
    h3: {
      fontFamily: "'Ubuntu', serif",
      fontWeight: 700,
      fontSize: '40px',
      lineHeight: '44px',
      '@media (max-width:600px)': {
        fontSize: '30px',
        lineHeight: '32px',
      },
    },
    h4: {
      fontFamily: "'Ubuntu', serif",
      fontWeight: 700,
      fontSize: '36px',
      lineHeight: '40px',
      '@media (max-width:600px)': {
        fontSize: '26px',
        lineHeight: '30px',
      },
    },
    h5: {
      fontFamily: "'Ubuntu', serif",
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '36px',
      '@media (max-width:600px)': {
        fontSize: '24px',
        lineHeight: '28px',
      },
    },
    h6: {
      fontFamily: "'Ubuntu', serif",
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
      '@media (max-width:600px)': {
        fontSize: '24px',
        lineHeight: '32px',
      },
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '32px',
      '@media (max-width:600px)': {
        fontSize: '20px',
        lineHeight: '26px',
      },
    },
    subtitle2: {
      fontWeight: 350,
      fontSize: '20px',
      lineHeight: '28px',
      '@media (max-width:600px)': {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    body1: {
      fontWeight: 350,
      fontSize: '18px',
      lineHeight: '26px',
    },
    body2: {
      fontWeight: 350,
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
});

export default muiTheme;
