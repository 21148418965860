import React from "react";
import { Box } from "@mui/material";

import PropTypes from "prop-types";
import IconedItem from "./IconedItem";

function IconedItemsList({ items, ...props }) {
  return (
    <Box
      sx={{
        flexDirection: "column",
        display: "flex",
        gap: 2,
        ...props.sx,
      }}
      {...props}
    >
      {items.map(({ heading, description, icon }) => (
        <IconedItem
          key={heading}
          description={description}
          icon={icon}
          title={heading}
        />
      ))}
    </Box>
  );
}

IconedItemsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default IconedItemsList;
