import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";

const BackgroundImageBox = ({ image, children, ...props }) => {
  return (
    <Box
      sx={{
        margin: "0 auto",
        marginTop: { xs: 3, lg: 8 },
        borderRadius: "32px",
        overflow: "hidden", // Ensures border-radius applies to the background
        paddingLeft: { xs: 0, lg: 4 },
        paddingRight: { xs: 0, lg: 4 },
        maxWidth: "1440px",
        ...props.sx
      }}
    >
      {/* Background Box */}
      <Box
        sx={{
          height: "400px",
          width: "100%",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: "inherit", // Matches parent border-radius
        }}
      />
      {/* Content Area */}
      <Box
        sx={{
          marginTop: "-250px", // Optional: overlap content slightly with the background if needed
          zIndex: 1,
          paddingLeft: { xs: 2, lg: 8 },
          paddingRight: { xs: 2, lg: 8 },
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            backgroundColor: "white",
            borderTop: "5px solid #1B5E20",
            borderRadius: 6,
            padding: { xs: 4, lg: 6 },
            paddingLeft: { xs: 2, lg: 8 },
            paddingRight: { xs: 2, lg: 8 },
            gap: 6,
            textAlign: "center",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

BackgroundImageBox.propTypes = {
  image: PropTypes.string.isRequired, // Image URL for the background
  children: PropTypes.node, // Optional content inside the box
};

export default BackgroundImageBox;
