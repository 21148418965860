import React, { useState } from "react";
import HeroSection from "../components/HeroSection";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { FormEndpoint } from "./constants";

import ContentSection from "../components/ContentSection";

import GreenMountainsLakeTrees from "../assets/images/GreenMountainsLakeTrees.jpg";
import CompanyInformation from "../components/CompanyInformation";
import { useLanguage } from "../utils/languages/LanguageContext";

function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const { getTranslationKeyData } = useLanguage();
  const text = getTranslationKeyData("pages.contact");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await fetch(FormEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess(true);
        setFormData({ name: "", email: "", message: "" });
      } else {
        setSuccess(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <HeroSection
        backgroundImage={GreenMountainsLakeTrees}
        heading={text.hero.mainHeaderTitle}
        subheading={text.hero.mainHeaderSubtitle}
      />
      <Box
        sx={{
          // backgroundImage: `url(${Svg})`,
          backgroundSize: { xs: "0%", lg: "35%" },
          backgroundPosition: { lg: "bottom -200px left 100px" },
          backgroundRepeat: "no-repeat",
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        <ContentSection>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", lg: "row" },
              gap: 6,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                gap: 1,
                flex: 1,
                flexShrink: 0,
              }}
            >
              <CompanyInformation />
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.9226972937126!2d14.437691361099246!3d50.05027938914842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b93962265de57%3A0x6088274ae61270ea!2sNa%20Str%C5%BEi%201702%2F65%2C%20140%2000%20Praha%204-Nusle%2C%20Czechia!5e0!3m2!1sen!2suk!4v1735325445801!5m2!1sen!2suk"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </Box>
            <Box
              sx={{
                maxWidth: 500,
                p: 3,
                border: "1px solid #ddd",
                borderRadius: 2,
                boxShadow: 2,
                flex: 1,
                flexShrink: 0,
              }}
            >
              <Typography variant="subtitle2" gutterBottom>
                {text.formFields.title}
              </Typography>
              {success !== true && (
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label={text.formFields.name}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    margin="normal"
                    required
                    inputProps={{ autoComplete: "off" }}
                  />
                  <TextField
                    fullWidth
                    label={text.formFields.email}
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    margin="normal"
                    required
                    inputProps={{ autoComplete: "off" }}
                  />
                  <TextField
                    fullWidth
                    label={text.formFields.message}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    margin="normal"
                    multiline
                    rows={4}
                    required
                    inputProps={{ autoComplete: "off" }}
                  />
                  <Box sx={{ textAlign: "center", mt: 2 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Box>
                </form>
              )}
              {success === true && (
                <Alert severity="success" sx={{ mt: 2 }}>
                  Message sent successfully!
                </Alert>
              )}
              {success === false && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  Failed to send the message. Please try again.
                </Alert>
              )}
            </Box>
          </Box>
        </ContentSection>
      </Box>
    </>
  );
}

export default ContactPage;
