import React from "react";
import { Box, Typography } from "@mui/material";

import PropTypes from "prop-types";

const HeroSectionV2 = ({
  heading,
  subheading = undefined,
  dark = false,
  children,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        background: `linear-gradient(to ${
          dark ? "bottom" : "top"
        }, #475C45, #E8F5E9)`,
        paddingTop: { xs: "62px", lg: "64px" },
        justifyContent: "space-between",
        ...props.sx,
      }}
    >
      {/* Content */}
      <Box
        textAlign="center"
        marginTop={10}
        paddingLeft={2}
        paddingRight={2}
        color={dark ? "white" : undefined}
      >
        <Typography variant="h1" component="h1">
          {heading}
        </Typography>
        <Typography
          variant="subtitle2"
          component="h2"
          margin="0 auto"
          marginTop={{ xs: 3, lg: 1 }}
          maxWidth="800px"
        >
          {subheading}
        </Typography>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          marginTop: 10,
          backgroundColor: "white",
          maxWidth: "1170px",
          borderTop: "5px solid #1B5E20",
          borderRadius: "32px 32px 0 0",
          padding: { xs: 4, lg: 6 },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

HeroSectionV2.propTypes = {
  children: PropTypes.node,
  dark: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
};

export default HeroSectionV2;
